import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, QuestionSecondaryText } from 'Atoms';
import { Container, LabelWrapper, ImagePollWrapper } from './ImagePoll.styles';
import { isSelected } from './utils';
import { ImageContent } from './ImageContent';
import { ImagePollProps, PollImage } from './types';

const ImagePoll: React.FC<ImagePollProps> = ({
  label,
  values,
  currentSelections,
  handleChange,
  selectMultiple,
  isMap, // Does the image poll appear as a map question. (Forces mobile)
  showImageLabels,
  ...props
}) => {
  const [selections, setSelections] = React.useState(currentSelections || []);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (currentSelections) {
      setSelections(currentSelections);
    }
  }, [currentSelections]);

  const handleSelection = (value: string) => {
    let newSelections;
    if (!selections.includes(value)) {
      newSelections = [...selections, value];
      setSelections(newSelections);
    } else {
      newSelections = [...selections];
      newSelections.splice(selections.indexOf(value), 1);
      setSelections(newSelections);
    }
    handleChange(newSelections);
  };

  const handleSingleSelection = (value: string) => {
    let newSelections;

    if (selections.includes(value)) {
      const _selections = [...selections];
      const newSelections = _selections.filter((v) => v !== value);
      setSelections(newSelections);
      return handleChange(newSelections as [string]);
    } else {
      newSelections = [value];
      setSelections(newSelections);
      handleChange(newSelections);
    }
  };

  const handleClick = (value) => {
    return selectMultiple
      ? handleSelection(value)
      : handleSingleSelection(value);
  };

  return (
    <Container data-testid="ImagePollId" isMap={isMap}>
      <FormControl style={{ width: '100%' }} {...props}>
        <LabelWrapper>
          <InputLabel isMap={isMap}>{label}</InputLabel>
        </LabelWrapper>
        <QuestionSecondaryText>
          {selectMultiple
            ? t('Select one or more options')
            : t('Select one option')}
        </QuestionSecondaryText>
        <ImagePollWrapper>
          {values.map((value: PollImage) => (
            <ImageContent
              key={`tag-item-${value.title}`}
              showImageLabels={showImageLabels}
              value={value}
              isMap={isMap}
              selected={isSelected(value, selections)}
              aria-pressed={isSelected(value, selections)}
              onClick={() => handleClick(JSON.stringify(value))}
              aria-label={value.label}
            />
          ))}
        </ImagePollWrapper>
      </FormControl>
    </Container>
  );
};

export { ImagePoll };
