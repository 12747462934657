import * as React from 'react';
import styled, { css } from 'styled-components';
import { readableColor } from 'polished/lib';
import { Typography } from 'Atoms';
import {
  sentimentSmilies,
  sentimentThemeValues,
} from 'Client/constants/sentiments';
import { RatingButtonProps } from './types';

export const RatingButton: React.FC<RatingButtonProps> = ({
  index,
  onClick,
  option,
  type,
  isSelected,
  isMap,
  questionId,
  showSentimentColours,
  showColours,
  ...props
}: RatingButtonProps) => {
  return (
    <Button
      data-testid={`RatingButton-${type}`}
      key={`${questionId}-${type}-${option.value}-${index}`}
      index={index}
      onClick={() => onClick(option.value)}
      selected={isSelected}
      sentimentColourMapping={
        showSentimentColours ? sentimentThemeValues[option.value] : null
      }
      colourHex={showColours && option?.colourHex}
      isMap={isMap ?? false}
      type={type}
      aria-selected={isSelected}
      {...props}
    >
      {type === 'number' && (
        <Typography variantMapping={{ body1: 'span' }}>
          {option.label}
        </Typography>
      )}
      {type === 'smilie' && (
        <>
          {sentimentSmilies[option.value]}
          <Typography variant="srOnly">{option.label}</Typography>
        </>
      )}
    </Button>
  );
};

const Button = styled(
  ({ selected, sentimentColourMapping, type, isMap, colourHex, ...props }) => (
    <button {...props} />
  )
)`
  ${({ theme, type, isMap, colourHex, sentimentColourMapping, selected }) => {
    const mainColor = sentimentColourMapping
      ? theme.colorMappings[sentimentColourMapping]
      : colourHex || '#FFFFFF';
    const hoverColor =
      mainColor === '#FFFFFF'
        ? theme.colorMappings.selectedHighlight
        : mainColor;
    const selectedColor =
      mainColor === '#FFFFFF'
        ? theme.colorMappings.selectedHighlight
        : mainColor;

    return css`
      position: relative;
      cursor: pointer;
      border: 0.0625rem solid ${theme.colorMappings.formFieldBorder};
      color: ${theme.colorMappings.formFieldLabel};
      background-color: ${theme.colorMappings.white};
      padding: 0 0.75rem;

      &:hover {
        background-color: ${hoverColor};
        color: ${readableColor(hoverColor)};
      }

      > span {
        font-weight: 700;
        font-size: 1.25rem;
        white-space: nowrap;
      }

      &:focus-visible {
        outline: none;
      }

      ${type === 'number' &&
      css`
        border-radius: 1.5625rem;
        height: 2.5rem;
        min-width: 2.5rem;

        &:focus {
          &:after {
            content: '';
            position: absolute;
            top: -0.5rem;
            left: -0.5rem;
            right: -0.5rem;
            bottom: -0.5rem;
            border-radius: 1.5625rem;
            border: 0.1875rem solid ${theme.colorMappings.focusHighlight};
            z-index: 1;
          }
        }

        ${selected &&
        css`
          box-shadow: inset 0px 0px 0px 3px ${theme.colors.grey[900]};
        `}
      `}

      ${type === 'smilie' &&
      css`
        border: none;
        border-radius: 50%;
        padding: 0;
        width: ${isMap ? 2.8125 : 3.4375}rem;
        height: ${isMap ? 3 : 3.375}rem;

        svg {
          width: ${isMap ? 2.8125 : 3.4375}rem;
          height: ${isMap ? 2.8125 : 3.4375}rem;
        }

        &:focus {
          &:after {
            content: '';
            position: absolute;
            top: -0.5rem;
            bottom: -0.5rem;
            right: -0.5rem;
            left: -0.5rem;
            width: ${isMap ? 3.8125 : 4.375}rem;
            height: ${isMap ? 3.8125 : 4.375}rem;
            border-radius: 50%;
            border: 0.1875rem solid ${theme.colorMappings.focusHighlight};
            z-index: 1;
          }
        }
      `}

      ${selected &&
      css`
        background-color: ${selectedColor};
        border-color: ${selectedColor};
        color: ${readableColor(mainColor)};

        svg > path:first-child {
          fill: transparent;
        }
      `}
    `;
  }}
`;
