import * as React from 'react';
import { ErrorStatusOrHelperText, InputLabel } from 'Atoms';
import { RatingOption } from 'Client/pages/proposals/types';
import { RatingButton } from './RatingButton';
import type { RatingProps } from './types';
import {
  RatingContent,
  RatingButtonWrapper,
  OptionWrapper,
  LabelWrapper,
  OptionLabel,
  LeftRightLabelsWrapper,
} from './Rating.styles';
import { normaliseOptions } from './utils/normaliseOptions';
import { isSelected } from './utils/isSelected';

/**
 * Everything comes from the db.
 * No default hardcoded options are passed here, regardless of rating-number or smilie questions.
 * All options are of the format { label: string, value: number | string, colourHex?: string }
 * The only reason why we need to initialise/normalise the options
 * is for backwards compatibility for old rating-number questions
 * where the options were an array of numbers.
 * The selected prop and the correct smilie are taken by the value, which is always a number for feeling questions.
 */

export const Rating: React.FC<RatingProps> = ({
  label,
  leftText,
  rightText,
  type,
  // useSentimentValues,
  onClick,
  status,
  value,
  question,
  showOptionLabels,
  isMap,
  showLeftRightLabels,
  ...props
}: RatingProps) => {
  const [selectedOptions, setSelectedOptions] = React.useState<
    Array<RatingOption>
  >(
    normaliseOptions(question.options as Array<string | number | RatingOption>)
  );

  React.useEffect(() => {
    // the hook is there to handle draft contributions // TODO test this
    setSelectedOptions(
      normaliseOptions(
        question.options as Array<string | number | RatingOption>
      )
    );
  }, [question.options]);

  const handleClick = (clickedValue: number) => {
    onClick(clickedValue);
  };

  return (
    <RatingContent isMap={isMap} {...props}>
      <InputLabel isMap={isMap}>{label}</InputLabel>
      <RatingButtonWrapper isMap={isMap}>
        {selectedOptions.map((option: RatingOption, index: number) => (
          <OptionWrapper
            showOptionLabels={showOptionLabels}
            key={`${question.id}-${type}-${option.value}-${index}`}
          >
            <RatingButton
              aria-label={option.label}
              questionId={question.id}
              index={index}
              onClick={handleClick}
              option={option}
              type={type}
              isMap={isMap}
              isSelected={isSelected(option.value, value)}
              aria-pressed={isSelected(option.value, value)}
              showSentimentColours={
                question.name === 'feeling' || question.type === 'smilie'
              }
              showColours={Boolean(question.showColours)}
            />
            {showOptionLabels && !isMap && (
              <LabelWrapper>
                <OptionLabel aria-hidden="true">{option.label}</OptionLabel>
              </LabelWrapper>
            )}
          </OptionWrapper>
        ))}
      </RatingButtonWrapper>
      {showLeftRightLabels && leftText && rightText && (
        <LeftRightLabelsWrapper>
          <p>{leftText}</p>
          <p>{rightText}</p>
        </LeftRightLabelsWrapper>
      )}
      {status?.message && <ErrorStatusOrHelperText status={status} />}
    </RatingContent>
  );
};
