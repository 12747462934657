import * as React from 'react';
import { useRouter } from 'next/router';
import { captureException } from '@sentry/node';
import { useLazyQuery } from '@apollo/client';
import { useProject } from 'Client/utils/hooks';
import { getProgrammeContentBlock } from 'Client/pages/edit/utils';
import { CustomOrderForm } from 'Client/pages/edit/components/Editor/components/CustomOrderForm';
import { GET_PROGRAMMES } from 'Client/services/programme/getProgrammes.gql';

export const ProgrammeEditorCustomOrderProvider = ({
  rules,
  setRules,
  index,
  updateOrder,
}) => {
  const [getProgrammes] = useLazyQuery(GET_PROGRAMMES, {});
  const { locale } = useRouter();
  const project = useProject();
  const { content } = getProgrammeContentBlock(project.customer);

  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      let _options = options;
      if (!options.length) {
        _options = await fetchProjects();
      }
      rulesToOptions(_options);
    })();
  }, [rules, index]);
  const fetchProjects = async () => {
    try {
      setLoading(true);
      const { data } = await getProgrammes({
        variables: {
          getProgrammesInput: {
            rules: content.rules,
            order: content.order,
            lang: locale,
            customerName: project.customer,
            editMode: true,
          },
        },
      });
      const projectsRaw = data?.getProgrammes || [];
      const parsedProjects = await projectsRaw.map((p) => {
        return {
          _id: p._id,
          title: p.name || p.title || '',
          selected: true,
        };
      });
      setOptions(parsedProjects);
      return parsedProjects;
    } catch (e) {
      captureException(e);
    } finally {
      setLoading(false);
    }
  };

  const rulesToOptions = async (_options) => {
    // Get default rule. We use this to base our list of projects off
    setLoading(true);

    // Get projects for drag and drop list
    try {
      const ids = rules?._id?.value.split(';');

      const [selected, unselected] = _options.reduce(
        (acc, opt) => {
          if (ids.includes(opt._id)) {
            acc[0].push({ ...opt, selected: true });
          } else {
            acc[1].push({ ...opt, selected: false });
          }
          return acc;
        },
        [[], []]
      );

      const allProjects = [...selected, ...unselected];
      setOptions(allProjects);
    } catch (error) {
      captureException(
        `error in handleData @ ProgrammeEditorCustomOrderProvider.tsx : ${error}`
      );
    } finally {
      setLoading(false);
    }
  };

  const updateOptions = (newOptions) => {
    setOptions(newOptions);

    // Object based! Dynamic Proposals is array based.
    setRules({
      ['_id']: {
        condition: 'is-in',
        value: newOptions
          .filter((p) => p.selected)
          .map((p) => p._id)
          .join(';'),
      },
    });
  };

  return (
    <CustomOrderForm
      setOptions={updateOptions}
      options={options}
      updateOrder={updateOrder}
      loading={loading}
      blockType={'programme'}
    />
  );
};
