import * as React from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';
import { InputLabel, QuestionSecondaryText } from 'Atoms';
import { Container } from 'Atoms/Container/Container';
import type { PriorityListProps } from './types';

const DragAndDropList = dynamic(() => import('./DragAndDropList'), {
  ssr: false,
});

const PriorityList: React.FC<PriorityListProps> = ({
  items,
  onChange,
  label,
  isMap,
  ...props // data-questionid
}: PriorityListProps) => {
  const { t } = useTranslation();
  const [winReady, setWinReady] = React.useState(false);
  React.useEffect(() => {
    setWinReady(true);
  }, []);

  return (
    <ContainerLocal {...props}>
      <LabelWrapper isMap={isMap}>
        <InputLabel>{label}</InputLabel>
      </LabelWrapper>
      <QuestionSecondaryText>
        {t('Sort in order of importance, with most important at the top.')}
      </QuestionSecondaryText>
      {winReady ? (
        <DragAndDropList items={items} onChange={onChange} isMap={isMap} />
      ) : null}
    </ContainerLocal>
  );
};

const ContainerLocal = styled(Container)`
  z-index: 100;
  padding: 0;
`;

const LabelWrapper = styled(({ ...props }) => <div {...props} />)`
  text-align: center;
  ${({ isMap }) =>
    isMap &&
    `
      font-weight: bold;
  `};
`;

export { PriorityList };
