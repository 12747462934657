import * as React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  Content,
  ImageWrapper,
  HoverContainer,
  SelectedContainer,
  SelectedCheck,
  CheckCircleOutline,
  Label,
} from './ImageContent.styles';
import { CloudinaryImage } from './../../organisms/CloudinaryImage';
import type { ImageContentProps } from './types';

export const ImageContent: React.FC<ImageContentProps> = ({
  showImageLabels,
  value,
  selected,
  isMap,
  onClick,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  // Image stays same size, even at desktop.
  const srcSets = [
    {
      imageWidth: 198,
      mediaQuery: `(min-width:${theme.breakpoints.values['md']}px)`,
    },
    {
      imageWidth: 328,
      mediaQuery: `(min-width:${theme.breakpoints.values['sm']}px)`,
    },
    {
      imageWidth: 267,
      mediaQuery: `(min-width:0px)`,
    },
  ];

  return (
    <Content data-testid="ImagePoll-ImageContent" {...props} aria-hidden="true">
      <ImageWrapper
        title={value.title}
        isMap={isMap}
        showImageLabels={showImageLabels}
        tabIndex={0}
        onClick={onClick}
        aria-selected={selected}
        aria-pressed={selected}
      >
        <CloudinaryImage imageSrc={value.url} srcSets={srcSets} crop="scale" />
        {!selected && !isMobile && (
          <HoverContainer>
            <CheckCircleOutline />
          </HoverContainer>
        )}
        {selected && (
          <SelectedContainer>
            <SelectedCheck />
          </SelectedContainer>
        )}
      </ImageWrapper>
      {showImageLabels && (
        <Label onClick={onClick} aria-hidden="true">
          {value.label}
        </Label>
      )}
    </Content>
  );
};
