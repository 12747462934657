import * as React from 'react';
import styled, { css } from 'styled-components';
import SwitchBase from '@material-ui/core/Switch';
import MenuBase from '@material-ui/core/Menu';
import MenuItemBase from '@material-ui/core/MenuItem';
import ListItemIconBase from '@material-ui/core/ListItemIcon';
import { Typography } from 'Atoms';
import { getMenuItemColor } from '.';

// Toggle tile
export const Switch = styled(({ checked, ...props }) => (
  <SwitchBase checked={checked} {...props} />
))`
  margin-left: -0.625rem;
  margin-top: -0.5rem;
  vertical-align: top;

  & .MuiSwitch-switchBase {
    position: absolute;
    padding: ${({ checked }) =>
      checked ? '0.875rem 0.625rem' : '0.875rem 0.9375rem'};
  }

  & .MuiSwitch-track {
    background-color: ${({ theme, checked }) =>
      checked
        ? theme.colorMappings.editModePrimaryColor
        : theme.colors.grey[500]} !important;
    height: 1.125rem !important;
    border-radius: 0.9375rem;
    opacity: 1 !important;
  }

  & .MuiSwitch-thumb {
    background-color: ${({ theme }) => theme.colorMappings.white};
    width: 0.875rem;
    height: 0.875rem;
  }
`;

export const SubheaderText = styled((props) => <Typography {...props} />)`
  margin: 0;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colorMappings.greyTextDarker};
`;

export const SubheaderContent = styled.div`
  margin: 0;
`;

export const TileHeader = styled.h3`
  position: relative;
  margin: 0;
`;

export const TileTitle = styled(({ ...props }) => <Typography {...props} />)`
  font-size: 1.125rem;
  ${({ isSelected, theme }) =>
    isSelected ? `color: ${theme.colorMappings.editModePrimaryColor}` : ''};
  display: inline-block;
  width: 100%;
`;

export const NewTitle = styled(TileTitle)`
  margin: 0;
`;

export const TileIcon = styled.div`
  margin-left: 0;

  svg {
    height: 3rem;
    width: 8rem;
  }
`;

export const ActionsButtonWrapper = styled.div<{ locked?: boolean }>`
  ${({ theme, locked }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    float: right;
    position: absolute;
    right: 0;
    top: ${locked ? '0rem' : '-0.8rem'};

    > button {
      padding: 0;
      .MuiSvgIcon-root {
        color: ${theme.colorMappings.icon};
      }
      &:hover {
        .MuiSvgIcon-root {
          color: ${theme.colorMappings.activeIcon};
        }
      }
    }
  `}
`;

// Text indicator
export const TextIndicatorWrapper = styled.div`
  width: 100%;
`;

export const LineIndicator = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.grey[300]};
  `}
  height: 0.4rem;
  border-radius: 0.375rem;
  margin: 0.3125rem 0;
  &:last-of-type {
    width: 60%;
  }
`;

// Default tile layout
export const DefaultTile = styled(({ ...props }) => <div {...props} />)`
  overflow: hidden; /* do not overflow outside the section block */
  padding: 0.5rem 1rem;
  border-radius: 0;
  border: 0.0625rem solid
    ${({ theme }) => theme.colorMappings.formFieldBorderLight};
  cursor: ${({ dragDisabled }) => (dragDisabled ? 'default' : 'grab')};

  &:hover {
    border: 0.0625rem solid
      ${({ theme }) => theme.colorMappings.formFieldBorder};
  }

  ${({ isDragging }) =>
    isDragging &&
    css`
      width: 20.5rem;
      background-color: ${({ theme }) => theme.colorMappings.white};
    `}

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      border: 0.0625rem solid ${theme.colorMappings.editModePrimaryColor};
      background-color: ${theme.colorMappings.editModeSelectedHighlight};
    `}
`;

// Action tile
export const ListItemIcon = styled(({ ...props }) => (
  <ListItemIconBase {...props} />
))`
  min-width: fit-content;
  margin-right: 0.8125rem;
  ${({ theme, action }) => `
    svg {
      color: ${getMenuItemColor(theme, action)};
    }
  `}
`;

export const Menu = styled(MenuBase)`
  padding-right: 0.625rem;

  ul {
    display: flex;
    flex-direction: column;

    li {
      width: 100%;
      justify-content: flex-start;
    }
  }
`;

export const MenuItem = styled(({ ...props }) => <MenuItemBase {...props} />)`
  padding: 0.5rem 1rem;
  font-weight: 600;
  ${({ theme, action }) => `
    color: ${getMenuItemColor(theme, action)} !important;
  `}
`;
