import * as React from 'react';
import styled, { css } from 'styled-components';
import { readableColor, darken } from 'polished';
import MuiBottomNavigation from '@material-ui/core/BottomNavigation';
import Grid from '@material-ui/core/Grid';
import { Button, ButtonProps, SimpleSelect } from 'Molecules';
import { LoadingButton, Typography } from 'Atoms';

export const NavItem = styled(Grid)`
  display: flex;
  place-content: center;
`;

export const EditButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.625rem;
  width: auto;
  button {
    width: auto;
  }
`;

const EditToolsButtonStyles = ({
  theme,
  inverse,
  link,
  hasErrors,
}: ButtonProps) => css`
  width: auto;
  min-height: unset;
  border-radius: 0.1875rem;
  font-size: 0.8rem;
  color: ${inverse
    ? theme.colors.white[500]
    : theme.colorMappings.greyTextDarker};
  &:hover {
    color: ${darken(0.2, theme.colorMappings.greyTextDarker)};
  }
  ${inverse &&
  css`
    background-color: ${theme.colorMappings.editButtonBackground};
    border-color: ${theme.colorMappings.editButtonBackground};
    &:hover {
      color: ${readableColor(
        darken(0.1, theme.colorMappings.editButtonBackground)
      )};
      background-color: ${darken(
        0.1,
        theme.colorMappings.editButtonBackground
      )};
      border-color: ${darken(0.1, theme.colorMappings.editButtonBackground)};
    }
  `}
  ${link &&
  css`
    border: 0.0625rem solid ${theme.colorMappings.greyTextDarker};
    &:hover {
      border: 0.0625rem solid ${darken(0.3, theme.colorMappings.greyTextDarker)};
    }
    &:focus {
      background-color: initial;
      &:after {
        content: '';
        position: absolute;
        top: -0.1875rem;
        left: -0.5rem;
        right: -0.5rem;
        bottom: -0.1875rem;
        border: 0.125rem solid ${theme.colorMappings.focusHighlight};
        z-index: 1;
      }
    }
  `}
  ${hasErrors &&
  css`
    background-color: ${theme.colorMappings.error};
    color: ${theme.colors.white[500]};
    border-color: ${theme.colorMappings.error};
    &:hover {
      background-color: ${darken(0.1, theme.colorMappings.error)};
      border-color: ${darken(0.1, theme.colorMappings.error)};
      color: ${theme.colors.white[500]};
    }
  `}
`;

export const EditToolsLoadingButton = styled((props) => (
  <LoadingButton {...props} />
))`
  ${EditToolsButtonStyles}
`;

export const PreviewPageButton = styled(({ ...props }) => <a {...props} />)`
  ${EditToolsButtonStyles};
  width: 6.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 0.625rem;

  svg {
    margin-right: 0.3125rem;
  }

  text-decoration: none;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
      opacity: 0.3;
    `}
`;

export const EditMapQuestionsButton = styled((props) => <Button {...props} />)`
  ${EditToolsButtonStyles}
  ${({ theme }) => css`
    background-color: ${theme.colorMappings.editModeSecondaryColor};
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    font-weight: bold;
    border: unset;
    color: ${theme.colors.black[500]};
    svg {
      margin-right: 0.5rem;
    }
    &:hover {
      background-color: ${theme.colors.grey[500]};
      color: ${theme.colors.black[500]};
      border: unset;
    }
  `}
`;

export const EditToolsButton = styled((props) => <Button {...props} />)`
  ${EditToolsButtonStyles}
`;

export const EditablePageSelect = styled(SimpleSelect)`
  display: inline-block;

  .MuiListItemText-primary {
    font-weight: 400;
    font-size: 1rem;
  }

  .react-select__control {
    min-height: 2.1875rem;
    height: 2rem;
    border-radius: 0.125rem;
    border-width: 0.0625rem;
    border-color: ${({ theme }) => theme.colorMappings.formFieldBorderLight};

    .react-select__dropdown-indicator {
      color: ${({ theme }) => theme.colorMappings.greyTextDarker};

      ${({ theme }) => theme.breakpoints.down('xs')} {
        padding: 0.5rem 0.2rem;
      }
    }

    .react-select__value-container {
      min-height: unset;
    }
  }
`;

export const ProposalStageSelect = styled((props) => (
  <EditablePageSelect {...props} width={'10rem'} />
))`
  ${(props) =>
    props.isDisabled &&
    css`
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 10;
        filter: blur(0rem) opacity(0.5);
        background: white;
      }
      && .react-select__dropdown-indicator {
        z-index: 1;
      }
    `}
`;

export const Toolbar = styled(MuiBottomNavigation)<{ isMap: boolean }>`
  align-items: center;
  position: relative;
  top: 0;
  height: 4rem;

  &.MuiBottomNavigation-root {
    width: 100%;
    z-index: 1200;
    ${({ theme, isMap }) =>
      !isMap &&
      `box-shadow: 0 0.3125rem 1.8125rem -1.25rem ${theme.colors.black[500]}`};
  }
`;

export const ButtonLabel = styled((props) => (
  <Typography variant="body2" variantMapping={{ body2: 'span' }} {...props} />
))`
  font-weight: 600;
  font-size: 0.8rem;
  width: fit-content;
`;

export const Label = styled((props) => <Typography {...props} />)`
  color: ${({ theme }) => theme.colorMappings.greyTextDarker};
  font-weight: 400;
  font-size: 1rem;
  display: inline-block;
  padding: 0 1rem;
`;

export const LanguageDropdown = styled.div`
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colorMappings.black} !important;
`;

export const RightSideSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;
export const LeftSideSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;

export const EditModePreviewMode = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
`;

export const Divider = styled.span`
  width: 0.125rem;
  height: 2.5rem;
  background-color: ${({ theme }) => theme.colors.grey[400]};
`;

export const EditToolsPreviewMethods = styled.button<{ active: boolean }>`
  ${({ theme, active }) => css`
    background-color: transparent;
    padding: 0;
    border: none;
    & > svg > path {
      fill: ${theme.colors.grey[400]};
    }
    &:hover {
      cursor: pointer;
      & > svg > path {
        fill: ${theme.colorMappings.svgHoveredColor};
      }
    }
    ${active &&
    `
      & > svg > path {
        fill: ${theme.colorMappings.svgSelectedColor};
      }
    `}
    &:focus {
      background-color: ${theme.colors.white[700]};
      border: 0.1563rem solid rgba(99, 148, 224, 0.27);
      outline: none;
      border-radius: 0.2344rem;
    }
  `}
`;

export const EditModeSelector = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  & > p {
    padding: 0;
  }
  align-items: center;
`;

export const ToolbarSection = styled.div`
  padding: 0 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 50%;
  justify-content: space-between;
  gap: 0.5rem;
`;
