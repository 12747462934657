import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  ErrorStatusOrHelperText,
  FormControl,
  InputLabel,
  QuestionSecondaryText,
} from 'Atoms';
import { useEditModeContext } from 'Client/utils/hooks';
import { useEditModeLayout } from 'Client/context/editModeLayoutContext';
import type { TextPollProps, Option } from '.';
import { OptionResult, AddOtherText } from './components';
import { mockResults } from './mockResults';
import { Content, OptionWrapper } from './TextPoll.styles';

// TODO change results prop from boolean to expected schema from database and map over to give results view instead of using hard coded example

const TextPoll: React.FC<TextPollProps> = ({
  label,
  options = [],
  currentSelections,
  status,
  handleChange,
  results,
  selectMultiple,
  isMap,
  horizontalOptions,
  showSecondaryText = true,
  addOtherText,
  roundedBorder,
  ...props
}: TextPollProps) => {
  const { t } = useTranslation();
  const [isEditModeLayout] = useEditModeLayout();
  const [editModeState] = useEditModeContext();

  const [selections, setSelections] = React.useState(currentSelections || []);
  const [showTextfield, setShowTextfield] = React.useState(false);
  const previewMode = editModeState?.editModeLayout?.previewMode;
  const theme = useTheme();
  const isMobile =
    useMediaQuery(theme.breakpoints.down('xs')) ||
    (isEditModeLayout && previewMode === 'mobile');

  const optionValues = options.map((option) => option.value);
  const editModeDesktopLayout = isEditModeLayout && previewMode === 'desktop';
  React.useEffect(() => {
    if (currentSelections) {
      setSelections(currentSelections);
    }
  }, [currentSelections]);

  const handleSelection = (value: string) => {
    let newSelections: Array<string>;
    if (!selections.includes(value)) {
      newSelections = [...selections, value];
      setSelections(newSelections);
    } else {
      newSelections = [...selections];
      newSelections.splice(selections.indexOf(value), 1);
      setSelections(newSelections);
    }
    handleChange(newSelections);
  };

  const handleSingleSelection = (value: string) => {
    let newSelections: Array<string>;
    if (selections.includes(value)) {
      // option is included already, so un-select it
      setSelections([]);
      handleChange([]);
    } else {
      newSelections = [value];
      setSelections(newSelections);
      handleChange(newSelections);
    }
    setShowTextfield(false);
  };

  const handleFreeTextChange = (val: string) => {
    const existingPollSelections = [
      ...selections.filter((sel) => optionValues.includes(sel)),
    ];
    setSelections([...existingPollSelections, val]);
    handleChange([...existingPollSelections, val]);
  };

  const handleSingleFreeTextChange = (val: string) => {
    setSelections([val]);
    handleChange([val]);
  };

  return (
    <FormControl isMap={isMap} {...props}>
      <InputLabel isMap={isMap}>{label}</InputLabel>
      {showSecondaryText && (
        <QuestionSecondaryText>
          {selectMultiple
            ? t('Select one or more options')
            : t('Select one option')}
        </QuestionSecondaryText>
      )}
      {results &&
        mockResults.map((option: Option) => (
          <OptionResult key={`option-${option.label}`} option={option} />
        ))}
      {!results && (
        <Content
          editModeDesktopLayout={editModeDesktopLayout}
          useDoubleColumn={options?.length > 6 && !isMap && !horizontalOptions}
          horizontalOptions={horizontalOptions}
        >
          {(options || []).map((option, index) => (
            <OptionWrapper
              aria-disabled={false}
              data-testid={`textpoll-option:${option.label}`}
              useDoubleColumn={
                !isMobile && options.length > 6 && !horizontalOptions
              }
              horizontalOptions={horizontalOptions}
              key={`option-${option.label}-${index}`}
              type="button"
              selected={selections.includes(option.value)}
              aria-pressed={selections.includes(option.value)}
              isMap={isMap}
              onClick={() =>
                selectMultiple
                  ? handleSelection(option.value)
                  : handleSingleSelection(option.value)
              }
              tabIndex={0}
              roundedBorder={roundedBorder}
              aria-label={option.label}
            >
              {/* { icon && icon} TODO */}
              {option.label}
            </OptionWrapper>
          ))}
        </Content>
      )}
      {addOtherText && (
        <AddOtherText
          questionLabel={label}
          onRegisterAnswer={
            selectMultiple ? handleFreeTextChange : handleSingleFreeTextChange
          }
          showTextfield={showTextfield}
          setShowTextfield={setShowTextfield}
        />
      )}
      {status?.message && (
        <ErrorStatusOrHelperText status={status} aria-describedby={label} />
      )}
    </FormControl>
  );
};

export { TextPoll };
