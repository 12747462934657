import * as React from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
  DroppableProvided,
} from 'react-beautiful-dnd';
import { DemographicsQuestion } from 'Shared/types/demographics';
import { DefaultEditProps } from '../types';
import { TileConstructor } from '../tiles/TileConstructor';
import { ListItem } from '../SectionPanel.styles';
import {
  EDITABLE_CONTENT_BLOCK_TYPES,
  EDITABLE_PAGE_TYPES,
} from '../../../constants';
import { TileProps } from '../tiles/types';

export const DefaultEditPanel: React.FC<DefaultEditProps> = ({
  handleDragEnd,
  handleDeleteTile,
  handleDuplicateTile,
  orderedItems = [],
  onCurrentEditItem,
  currentEditItem,
  toggleHideShow,
  benchmarkQuestions,
  viewType,
}: DefaultEditProps) => {
  const itemsWithBlockedDrag = [
    EDITABLE_CONTENT_BLOCK_TYPES.HERO,
    EDITABLE_CONTENT_BLOCK_TYPES.PROJECT_TEAM_HEADER,
    EDITABLE_CONTENT_BLOCK_TYPES.NAV,
  ];
  if (viewType === EDITABLE_PAGE_TYPES.PROPOSALS) {
    itemsWithBlockedDrag.push(
      EDITABLE_CONTENT_BLOCK_TYPES.TITLE,
      EDITABLE_CONTENT_BLOCK_TYPES.DESCRIPTION
    );
  }

  const isBenchmark = (id?: string): boolean => {
    return benchmarkQuestions
      ? benchmarkQuestions?.some(
          (benchmarkQuestion) => benchmarkQuestion?.questionId === id
        )
      : false;
  };

  const getSectionElementTestId = (type: EDITABLE_PAGE_TYPES) => {
    switch (type) {
      case EDITABLE_PAGE_TYPES.PROPOSAL:
      case EDITABLE_PAGE_TYPES.MAP:
        return 'ProposalSectionRender';
      case EDITABLE_PAGE_TYPES.DEMOGRAPHICS:
        return 'DemographicsSectionRender';
      case EDITABLE_PAGE_TYPES.TIMELINE:
      case EDITABLE_PAGE_TYPES.MILESTONES:
        return 'milestone-item-wrapper';
      case EDITABLE_PAGE_TYPES.PREFERENCES:
        return 'consents-option-container';
      case EDITABLE_PAGE_TYPES.HUB:
        return 'hub-block-item';
      case EDITABLE_PAGE_TYPES.PROPOSALS:
        return 'proposals-item-wrapper';
    }
  };

  const handleSectionClick = (item, index, locked) => {
    onCurrentEditItem(
      item.data.type,
      index,
      isBenchmark((item.data?.content as DemographicsQuestion)?.questionId),
      locked
    );

    const elementTestId = getSectionElementTestId(viewType);
    const itemIdentifier =
      item?.data?.content?._id ??
      item.data.content?.id ??
      item?.data?.content?.questionId ??
      item.data._id;

    const sectionElement = document.querySelector(
      `[data-testid="${elementTestId}"][data-scroll-id="${itemIdentifier}"]`
    );
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const tileHasMenu = (item: TileProps, index: number) => {
    switch (viewType) {
      case EDITABLE_PAGE_TYPES.PROPOSAL:
        return (
          index > 1 || // first two sections should not have menu
          !itemsWithBlockedDrag.includes(
            item.data.type as EDITABLE_CONTENT_BLOCK_TYPES
          )
        );
      case EDITABLE_PAGE_TYPES.HUB:
        if (item.data.type === EDITABLE_CONTENT_BLOCK_TYPES.NAV) {
          return false;
        }
        if (index === 0) {
          return false; // hero block
        }
        return true;
      default:
        true;
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided: DroppableProvided, snapshotDrop) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {orderedItems?.map((item, index) => {
              const realIndex = index;
              const dragDisabled =
                item?.data?.locked ||
                itemsWithBlockedDrag.includes(
                  item.data.type as EDITABLE_CONTENT_BLOCK_TYPES
                );
              return (
                <Draggable
                  key={`${index}-${item.data.title}`}
                  draggableId={`${index}-${item.data.type}`}
                  index={index}
                  isDragDisabled={dragDisabled}
                >
                  {(provided: DraggableProvided, snapshotDrag) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      key={item.data.title}
                      onClick={() =>
                        handleSectionClick(item, index, item?.data?.locked)
                      }
                    >
                      <ListItem
                        data-testid={`section-block-title:${item.data.title}`}
                        data-onboarding="SectionPanelListItem"
                        data-scroll-id={item.data.content?.['id']}
                        isDragging={snapshotDrag.isDragging}
                        notAllowedStyles={notAllowedStyles(
                          snapshotDrop.isDraggingOver,
                          snapshotDrag.isDragging
                        )}
                      >
                        <TileConstructor
                          key={item.data.title}
                          component={item.component}
                          locked={item.data.locked}
                          data={item.data}
                          index={realIndex}
                          isSelected={
                            currentEditItem.index === realIndex &&
                            currentEditItem.type === item.data.type
                          }
                          toggleHideShow={toggleHideShow}
                          onDeleteTile={handleDeleteTile}
                          onDuplicateTile={handleDuplicateTile}
                          {...(item.subheader && { subheader: item.subheader })}
                          isBenchmark={isBenchmark(
                            (item.data?.content as DemographicsQuestion)
                              ?.questionId
                          )}
                          hasMenu={tileHasMenu(item, index)}
                          dragDisabled={dragDisabled}
                        />
                      </ListItem>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const notAllowedStyles = (
  isDragginOver: boolean,
  isDragging: boolean
): boolean => {
  if (isDragging) {
    if (isDragginOver) {
      return false;
    } else {
      return true;
    }
  }
  return false;
};
